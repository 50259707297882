<template>
  <div>
    <!-- menu -->
    <transition name="slide">
      <div
        class="fixed flex flex-col bg-prime-green h-screen w-screen sm:max-w-sm sm:top-0 sm:right-0 z-20 px-5 pt-5"
        v-click-outside="onClickOutside"
        v-if="menuVisible"
      >
        <div class="flex flex-row items-center justify-end pb-5">
          <inertia-link href="/" class="mr-auto">
            <inline-svg
              :src="require('@/assets/logo-trim.svg')"
              class="fill-current h-6 ml-4 pt-1 text-green-2"
            />
          </inertia-link>
          <inline-svg
            class="fill-current text-green-2 mr-1 h-10 w-10 cursor-pointer"
            :src="require('@/assets/burger-menu-open.svg')"
            @click="toggleMenu"
          />
        </div>
        <v-menu class="px-4 overflow-y-auto flex-grow" />
      </div>
    </transition>

    <div class="bg-prime-background text-white min-h-screen flex flex-col">
      <div class="flex flex-row items-center justify-end bg-white m-5">
        <inertia-link
          v-if="returnHref"
          preserve-state
          :replace="returnHref.replace"
          :href="route(returnHref.path, returnHref.properties)"
        >
          <inline-svg
            :src="require('@/assets/arrow-right.svg')"
            class="fill-current h-10 text-green-2 flip"
          />
        </inertia-link>

        <!-- SHOULD ONLY BE USED WHEN IT'S NOT POSSIBLE TO PASS PROPS -->
        <a
          v-if="!returnHref"
          class="cursor-pointer"
          preserve-state
          @click="back()"
        >
          <inline-svg
            :src="require('@/assets/arrow-right.svg')"
            class="fill-current h-10 text-green-2 flip"
          />
        </a>
        <h1
          class="text-2xl font-black text-center uppercase text-prime-green mx-auto sm:ml-10 truncate ..."
        >
          {{ title }}
        </h1>
        <inline-svg
          @click="toggleMenu"
          :src="require('@/assets/burger-menu.svg')"
          class="fill-current text-green-2 mr-1 h-10 w-10 cursor-pointer"
        />
      </div>

      <div class="flex-grow flex flex-col">
        <slot />
      </div>

      <div
        class="self-center w-full flex text-green-2 py-4 px-5 tracking-wider justify-between max-w-sm"
      >
        <inertia-link
          :href="route('privacy-policy')"
          class="font-bold mr-4 text-sm"
        >
          {{ $t("components.authLayout.yourPrivacy") }}
        </inertia-link>
        <a href="https://lcd-media.com/" target="_blank" class="font-bold mr-4 text-sm">
          {{ $t("components.authLayout.aboutPrivacy") }}
        </a>
        <a href="mailto:hey@lcdprime.com" class="font-bold mr-4 text-sm">{{
          $t("components.authLayout.contactUs")
        }}</a>
        <inertia-link
          method="post"
          :href="route('logout')"
          class="font-bold text-sm"
          as="button"
        >
          {{ $t("components.menu.logout") }}
        </inertia-link>
      </div>
    </div>
  </div>
</template>
<script>
import VMenu from "../components/SlideMenu";
import vClickOutside from "click-outside-vue3";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    VMenu,
  },
  props: {
    title: String,
    returnHref: Object,
  },
  data() {
    return {
      menuVisible: false,
      year: new Date().getFullYear(),
      currentDate: new Date().toLocaleDateString().replaceAll("/", "."),
    };
  },
  computed: {
    user() {
      return this.$page.props.user;
    },
  },
  created() {
    this.$inertia.on("success", () => {
      this.menuVisible = false;
    });
  },
  mounted() {
    if (this.user) {
      this.$i18n.locale = this.user.preferredLocale;
    }
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    back() {
      window.history.back();
    },
    onClickOutside() {
      this.toggleMenu();
    },
  },
};
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(640px);
}
.flip {
  transform: scale(-1, 1);
}
</style>
