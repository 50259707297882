<template>
  <support-layout
    :menuIndex="3"
    :title="$t('pages.supportcase.casedetails')"
    :returnHref="desktopView ? null : 'executive.support-cases'"
  >
    <div>
      <div v-if="desktopView">
        <inertia-link
          class="mt-3 text-prime-grey-dark hover:text-green-2 font-bold py-2 uppercase underline text-xs absolute cursor-pointer pointer-events-auto z-10"
          :replace="false"
          :href="route('executive.support-cases', {})"
        >
          {{ $t("pages.supportcase.backtoexistingcases") }}
        </inertia-link>
        <p
          class="text-prime-green font-bold py-2 uppercase text-sm relative text-center z-0"
        >
          {{ $t("pages.supportcase.casedetails") }}
        </p>
      </div>
      <div class="grid grid-cols-2 mt-4">
        <div class="col-span-2 xl:col-span-1">
          <div class="grid grid-cols-3-7">
            <p class="text-prime-green">
              {{ $t("pages.supportcase.ticketnumber") }}:
            </p>
            <p class="text-prime-green font-bold">
              #{{ supportCase?.referenceKey }}
            </p>

            <p class="text-prime-green">
              {{ $t("pages.supportcase.subject") }}:
            </p>
            <p class="text-prime-green font-bold">{{ supportCase?.name }}</p>

            <p class="text-prime-green">
              {{ $t("pages.supportcase.status") }}:
            </p>
            <p class="text-prime-green font-bold">
              {{
                supportCase
                  ? $t(`common.supportCaseStatuses.${supportCase?.status}`)
                  : ""
              }}
            </p>

            <p class="text-prime-green">
              {{ $t("pages.supportcase.created") }}:
            </p>
            <p class="text-prime-green font-bold">
              {{ formatDate(supportCase?.createdAt) }}
            </p>
          </div>
        </div>
        <div
          v-if="isCaseDetailsExpanded || desktopView"
          class="col-span-2 xl:col-span-1"
        >
          <div class="grid grid-cols-3-7">
            <p class="text-prime-green">
              {{ $t("pages.supportcase.locationname") }}:
            </p>
            <p
              class="text-prime-green font-bold"
              v-html="supportCase?.location?.description"
            ></p>

            <p class="text-prime-green">
              {{ $t("pages.supportcase.locationaddress") }}:
            </p>
            <p class="text-prime-green font-bold">
              {{ supportCase?.location?.addressLine1 }}
              {{ supportCase?.location?.addressLine2 }},
              {{ supportCase?.location?.postalCode }}
              {{ supportCase?.location?.city }},
              {{ supportCase?.location?.country }}
            </p>

            <p class="text-prime-green">
              {{ $t("pages.supportcase.solutionname") }}:
            </p>
            <p class="text-prime-green font-bold">
              {{ supportCase?.solution?.solutionTemplate?.name }}
            </p>

            <p class="text-prime-green">
              {{ $t("pages.supportcase.username") }}:
            </p>
            <p class="text-prime-green font-bold">
              {{ supportCase?.user?.name }}
            </p>
          </div>
        </div>
        <button
          v-if="!desktopView"
          class="text-prime-grey-dark hover:text-green-2 font-light col-span-2 font-bold relative py-2 uppercase underline text-xs"
          @click="isCaseDetailsExpanded = !isCaseDetailsExpanded"
        >
          {{
            isCaseDetailsExpanded
              ? $t("pages.supportcase.collapsemoreinfo")
              : $t("pages.supportcase.expandmoreinfo")
          }}
        </button>
      </div>
      <span class="text-lg text-green-2 mt-6 font-light inline-block uppercase">
        {{
          isSupportCaseClosed
            ? $t("pages.supportcase.problemnotsolved")
            : $t("pages.supportcase.writenewmessage")
        }}
      </span>
      <div class="flex flex-col mt-1">
        <textarea
          v-model="messageForm.message"
          :placeholder="$t('pages.newsupportcase.descriptionplaceholder')"
          rows="3"
          class="outline-none text-prime-black h-40 w-full placeholder-prime-grey-dark border-prime-grey-dark px-4 py-2 resize-none rounded flex-grow"
        />

        <div
          class="flex flex-col sm:flex-row items-start w-full sm:justify-between"
        >
          <div class="flex flex-col items-start text-green-2 w-full sm:w-4/5">
            <file-input
              :files="messageForm.files"
              :showFiles="true"
              @files-added="(files) => messageForm.files.push(...files)"
              @file-removed="
                (file) =>
                  (messageForm.files = messageForm.files =
                    messageForm.files.filter((f) => f !== file))
              "
              @files-cleared="() => (messageForm.files = [])"
              class="my-2"
            />
          </div>
          <button
            class="mt-4 py-2 mx-2 h-10 uppercase text-sm text-white w-full base-button bg-green-2 hover:bg-prime-green-dark active:bg-prime-green sm:w-1/5"
            :disabled="isCloseCaseButtonDisabled"
            @click="closeCase"
          >
            {{ $t("pages.supportcase.closecase") }}
          </button>
          <button
            class="mt-4 py-2 ml-2 h-10 uppercase text-sm text-white w-full base-button bg-green-2 hover:bg-prime-green-dark active:bg-prime-green sm:w-1/5"
            :disabled="isMessageFormDisabled"
            @click="sendMessage"
          >
            {{ $t("components.supportcasemessage.sendmessage") }}
          </button>
        </div>
      </div>
      <span
        v-if="desktopView || isChatExpanded"
        class="text-lg text-green-2 font-light mt-6 inline-block uppercase"
      >
        {{ $t("pages.supportcase.supportchat") }}
      </span>
      <div class="flex flex-col mt-1">
        <transition-expand v-if="desktopView || isChatExpanded">
          <div>
            <div
              v-for="(message, index) in supportChatMessages"
              :key="message.id"
              :class="index % 2 === 0 ? 'bg-gray-200' : 'bg-gray-300'"
              class="px-1 text-prime-green"
              style="min-height: 100px"
            >
              <div class="flex justify-between">
                <span class="w-1/2 break-all">{{
                  message.user?.name ?? message.fallbackName
                }}</span>
                <span class="w-1/2 text-right">{{
                  formatDate(message.createdAt)
                }}</span>
              </div>
              <span class="whitespace-pre-wrap break-all">{{
                message.message
              }}</span>
              <div v-if="!!message.files.length" class="text-md flex flex-col">
                <span class="italic"
                  >{{ $t("pages.supportcase.attachments") }}:</span
                >
                <a
                  v-for="file in message.files"
                  :key="file.id"
                  :href="
                    route('api.files.content', {
                      id: file.id,
                      filename: file.originalName
                    })
                  "
                  target="_blank"
                  class="max-h-7 overflow-x-hidden overflow-ellipsis underline leading-7"
                >
                  {{ file.originalName }}
                </a>
              </div>
            </div>
          </div>
        </transition-expand>
        <button
          class="text-prime-grey-dark hover:text-green-2 font-bold relative py-2 uppercase underline text-xs"
          @click="isChatExpanded = !isChatExpanded"
        >
          {{
            isChatExpanded
              ? $t("pages.supportcase.collapsechat")
              : $t("pages.supportcase.expandchat")
          }}
        </button>
      </div>
    </div>
  </support-layout>
</template>
<script>
import TransitionExpand from "@/components/TransitionExpand";
import { objectToFormData } from "@/util/formData";
import FileInput from "@/components/FileInput";
import SupportLayout from "./SupportLayout.vue";

export default {
  components: { SupportLayout, TransitionExpand, FileInput },
  props: {
    supportCase: Object,
  },
  data() {
    return {
      isChatExpanded: false,
      isCaseDetailsExpanded: false,
      messageForm: this.$inertia.form({
        message: null,
        files: [],
      }),
      closeCaseForm: this.$inertia.form(),
      desktopView: window.innerWidth >= 640,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.desktopView = window.innerWidth >= 640;
    });
  },
  methods: {
    formatDate(date) {
      const dateObject = new Date(date);

      return `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" }
      )}`.replaceAll("/", ".");
    },
    formatDatetime(date) {
      const dateObject = new Date(date);

      return `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit" }
      )}`.replaceAll("/", ".");
    },
    sendMessage() {
      if (this.isSupportCaseClosed) {
        let confirmation = confirm(
          this.$t("components.supportcasemessage.confirmreopen")
        );

        if (!confirmation) return;
      }

      this.messageForm
        .transform((data) =>
          objectToFormData({ ...data, supportCaseId: this.supportCase.id })
        )
        .post(this.route("my-support-case-messages.store"), {
          preserveScroll: true,
        });

      this.messageForm.message = null;
      this.messageForm.files = [];
    },
    closeCase() {
      let confirmation = confirm(this.$t("pages.supportcase.confirmclose"));

      if (!confirmation) return;

      this.closeCaseForm
        .transform(() => objectToFormData({ status: "Closed" }))
        .put(this.route("support-cases.update", this.supportCase.id), {
          preserveScroll: true,
        });
    },
  },
  computed: {
    supportChatMessages() {
      const messages = this.supportCase.messages;

      if (!messages?.length) return [];

      if (!this.isChatExpanded) {
        return [messages[0]];
      }

      return messages;
    },
    isSupportCaseClosed() {
      return this.supportCase.status === "Closed";
    },
    isMessageFormDisabled() {
      return (
        this.messageForm.processing ||
        !this.messageForm.message?.trim() ||
        this.closeCaseForm.processing
      );
    },
    messageSubmitButtonColor() {
      if (this.isMessageFormDisabled) return "bg-gray-300";
      if (this.isSupportCaseClosed) return "bg-warning";

      return "bg-brand7";
    },
    isCloseCaseButtonDisabled() {
      return (
        this.closeCaseForm.processing ||
        this.messageForm.processing ||
        this.supportCase.status === "Closed"
      );
    },
  },
};
</script>
